@import '../../../styles/custom-media.css';

.root {
  composes: center from '../../../styles/shared.module.css';
  position: relative;
  background-color: #fff;
  color: var(--color-white, #fff);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 2rem;

  @media (--media-min-medium) {
    padding-bottom: 4rem;
  }
}

.imageOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 0;
  clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 50% 88%);
}

.imageClip {
  clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 50% 88%);
}

.title {
  position: relative;
  font-weight: 600;
  font-size: var(--font-title2-size);
  line-height: var(--font-title2-line-height);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
  padding-top: 12.5rem;

  @media (--media-min-medium) {
    font-size: var(--font-title1-size);
    line-height: var(--font-title1-line-height);
    padding-top: 15rem;
  }
}

.tagline {
  position: relative;
  margin: 0;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

  @media (--media-min-medium) {
    font-size: var(--font-large-size);
    line-height: var(--font-large-line-height);
  }
}

.tagline > p {
  margin: 0;
}

.ctas {
  margin-top: 3rem;
  display: flex;
}

.ctas > *:not(:first-child) {
  margin-left: 1rem;
}

.root p a {
  color: inherit;
}

.heading {
  @apply text-primary font-extrabold uppercase;
  display: table-caption;
  word-spacing: 9999px;

  font-size: 11vw;
  line-height: 1;

  @screen md {
    font-size: 4.25rem;
    line-height: 1;
  }
  @screen xl {
    font-size: 5.625rem;
    line-height: 1;
  }
}
