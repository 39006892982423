.chevron {
	transition: transform 300ms;
}

[data-state="open"] .chevron {
	transform: rotate(180deg);
}

[data-state="open"] .header {
	background-color: theme("colors.primary");
	color: white;
}

.headerCircle {
	width: 1.25rem;
	height: 1.25rem;
	background-color: theme("colors.primary");
	border-radius: 9999px;
	flex-shrink: 0;
	margin-right: 0.75rem;
}

[data-state="open"] .headerCircle {
	background-color: white;
}

.counterReset {
	counter-reset: accordionSection;
}

.counterContent {
	position: relative;
}

.counterContent::before {
	counter-increment: accordionSection;
	content: counter(accordionSection);
	position: absolute;
	top: 60%;
	left: 55%;
	transform: translate(-50%, -50%);
}
