.scroller {
	animation-duration: var(--animation-duration);
	animation-name: scroll;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out;
	animation-play-state: var(--animation-play-state, paused);

	@media (prefers-reduced-motion) {
		animation: none;
	}
}

@keyframes scroll {
	/* Pause for a short amount of time at the start and end of the loop */
	0%,
	5% {
		transform: translateX(0);
	}
	95%,
	100% {
		transform: translateX(calc(var(--scroll-width)));
	}
}
