.link {
	@apply transition-colors;
	outline: none;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
		color: theme("colors.primary");
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
