.fadeImage {
	position: absolute;
	right: 0;
	height: 100%;
	width: 7rem;
	background: linear-gradient(
		90deg,
		rgba(201, 182, 128, 0) 0%,
		rgba(201, 182, 128, 0.23) 47%,
		rgba(201, 182, 128, 1) 100%
	);
}
