.bgOverlay {
	&::after {
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		content: "";
		background-color: black;
		opacity: 0.75;
	}
}

.textShadow {
	text-shadow: 2px 2px black;
}
